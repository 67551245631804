import React, { useEffect, useState } from 'react';
import images39 from '../assets/images/39.png';
import images40 from '../assets/images/40.png';
import images41 from '../assets/images/41.png';
import images42 from '../assets/images/42.png';
import images44 from '../assets/images/44.png';
import images45 from '../assets/images/45.png';
import images46 from '../assets/images/46.png';
import images04 from '../assets/images/04.png';
import images05 from '../assets/images/05.png';
import images06 from '../assets/images/06.png';
import images07 from '../assets/images/07.png';
import images08 from '../assets/images/08.png';
import images09 from '../assets/images/09.png';
import images10 from '../assets/images/10.png';
import images11 from '../assets/images/11.png';
import images12 from '../assets/images/12.png';
import images13 from '../assets/images/13.png';
import images14 from '../assets/images/14.png';
import images15 from '../assets/images/15.png';
import images16 from '../assets/images/16.png';
import images17 from '../assets/images/17.png';
import images18 from '../assets/images/18.png';
import images19 from '../assets/images/19.png';
import images20 from '../assets/images/20.png';
import images21 from '../assets/images/21.png';
import images22 from '../assets/images/22.png';
import images23 from '../assets/images/23.png';
import images24 from '../assets/images/24.png';
import images25 from '../assets/images/25.png';
import images26 from '../assets/images/26.png';
import images27 from '../assets/images/27.png';
import images28 from '../assets/images/28.png';
import dotnet from '../assets/images/dotnet.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TbChecks } from 'react-icons/tb';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
// import { useForm, SubmitHandler } from "react-hook-form"

const Main: React.FC = () => {

    AOS.init();

    var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    // nextArrow: <FaLongArrowAltLeft />,
    // prevArrow: <FaLongArrowAltRight />,
    responsive: [
      {
        breakpoint: 1826,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
          breakpoint: 1249,
          settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 2,
              slidesToScroll: 1,
          }
      },
      {
          breakpoint: 880,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
      }
    ]
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
      });
    
      const [formStatus, setFormStatus] = useState({
        success: false,
        error: false,
        message: ''
      });
    
      const handleChange = (e:any) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
      };
    
      const handleSubmit = async (e:any) => {
        e.preventDefault();
    
        // Additional data fields
        const deviceType = 'web';
        const fcmToken = 'dummy-token';
    
        // Merging formData with additional fields
        const payload = {
          ...formData,
          deviceType,
          fcmToken
        };
    
        try {
          const response = await axios({
            method: 'POST',
            url: 'https://rizycosmetic-001-site3.atempurl.com//v1/customerQuery/inserCustomerQuery',
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify(payload),
          });

    
          console.log('Response data:', response.data);
          setFormStatus({
            success: true,
            error: false,
            message: 'Your request has been submitted successfully!'
          });
    
          // Hide the success message after 2 seconds
          setTimeout(() => {
            setFormStatus({
              success: false,
              error: false,
              message: ''
            });
          }, 2000); // 2000ms = 2 seconds
    
          // Reset form data after successful submission
          setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            subject: '',
            message: '',
          });
        } catch (error) {
          console.error('Error:', error);
          setFormStatus({
            success: false,
            error: true,
            message: 'There was an error submitting your request. Please try again later.'
          });
        }
      };    

  return (
    <>
    <div className="container-fluid">
        <div className="row fest-section">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 left">
            <h6 className=" main-title mb-2" data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">
                We combine data and
                technology for World Leading
                <span>enterprise solutions</span>
            </h6>
            <p className=""  data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                Empowering innovation through cutting-edge software solutions. Welcome to our digital hub where creativity meets technology, driving your business forward with unparalleled efficiency and agility.
            </p>
            <div className="d-flex gap-3 mt-4">
                <button className="get-quote ani-text">Get Quote</button>
            </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 fest-section-images">
            <img src={images41} alt="" className="img-fluid images-one ani-text" />
        </div>
        </div>
        <div className="row">
        <div className="col-12">
            <div>
                <h5 className="main-title text-center" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <span>Exclusive</span> services for <br/> your business
                </h5>
            </div>
        </div>
        <div className="services">
          <Slider {...settings} className="slider exclusive-services">
            <div data-aos="flip-left">
                    <div className="exclusive-box">
                        <img src={images42} alt="" className="img-fluid"/>
                        <div>
                            <h5>Website</h5>
                            <h6>Application</h6>
                        </div>
                        <p>
                            We leverage the latest technologies and industry best practices to ensure your web application stands out in today's competitive landscape environment.
                        </p>
                     
                    </div>
            </div>
            <div data-aos="flip-left">
                    <div className="exclusive-box">
                        <img src={images04} alt="" className="img-fluid"/>
                        <div>
                            <h5>Mobile</h5>
                            <h6>Application</h6>
                        </div>
                        <p>
                            From sleek UI designs to seamless backend integration, we leverage the latest technologies and industry trends to deliver robust, scalable,
                            and secure mobile applications that drive results.
                        </p>
                        
                    </div>
            </div>
            <div data-aos="flip-left">
                    <div className="exclusive-box">
                        <img src={images45} alt="" className="img-fluid" />
                        <div>
                            <h5>Desktop</h5>
                            <h6>Application</h6>
                        </div>
                        <p>
                            We specialize in crafting desktop applications that combine functionality with elegance. Our team meticulously
                            develop desktop
                            software tailored to your specific requirements.
                        </p>
                    </div>
            </div>
            <div data-aos="flip-left">
                    <div className="exclusive-box">
                        <img src={images46} alt="" className="img-fluid"/>
                        <div>
                            <h5>Digital</h5>
                            <h6>Marketing</h6>
                        </div>
                        <p>
                          It encompasses a wide range of strategies and methods aimed at connecting with customers and driving business growth through digital channels.
                        </p>
                    </div>
            </div>
             <div data-aos="flip-left">
                    <div className="exclusive-box">
                        <img src={images44} alt="" className="img-fluid" />
                        <div>
                            <h5>SEO / ASO</h5>
                            <h6>Digital Marketing</h6>
                        </div>
                        <p>
                            Search engine optimization (SEO) to social media marketing and content creation.
                            We leverage the latest tools and techniques to optimize your online presence and traffic, leads, and conversions.
                        </p>

                    </div>
            </div>
          </Slider>
        </div>
        </div>
        <div className="row">
         <div className="col-12">
            <div>
                <h5 className="main-title text-center" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" >
                    <span>Our</span>
                    Valauble Clients
                </h5>
            </div>
         </div>
         <div className="col-12">
            <div className="row valauble-clients">
                <p className="box-shadow"></p>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 clients-box">
                    <div className="clients-name" data-aos="flip-left" data-aos-easing="linear"  data-aos-duration="800">
                        <img src={images05} className="img-fluid" />
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 clients-box">
                    <div className="clients-name" data-aos="flip-right" data-aos-easing="linear"  data-aos-duration="800">
                        <img src={images06} className="img-fluid" />
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 clients-box">
                    <div className="clients-name" data-aos="flip-up" data-aos-easing="linear"  data-aos-duration="800">
                        <img src={images07} className="img-fluid" />
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 clients-box">
                    <div className="clients-name" data-aos="flip-down" data-aos-easing="linear"  data-aos-duration="800">
                        <img src={images08} className="img-fluid" />
                    </div>
                </div>
            </div>
         </div>
        </div>
        <div className="row">
         <div className="col-12">
            <div>
                <h5 className="main-title text-center" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" >
                    <span>Technologies</span> We <br /> Work With
                </h5>
            </div>
         </div>
         <div className="col-12 technologies">
            <div className="boxs">
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <p className="box-shadow"></p>
                    <img src={dotnet} className="img-fluid" />
                    <h6>.Net</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800" >
                    <p className="box-shadow"></p>
                    <img src={images09} className="img-fluid" />
                    <h6>Photoshop</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images10} className="img-fluid" />
                    <h6>Android Studio</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images11} className="img-fluid" />
                    <h6>Firebase</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images12} className="img-fluid" />
                    <h6>Reactjs</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images13} className="img-fluid" />
                    <h6>Kotlin</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images14} className="img-fluid" />
                    <h6>Illustrator</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images15} className="img-fluid" />
                    <h6>Figma</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images16} className="img-fluid" />
                    <h6>Python</h6>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images17} className="img-fluid" />
                    <h6>XCode</h6>
                    <p className="box-shadow"></p>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images39} className="img-fluid" />
                    <h6>Angular</h6>
                    <p className="box-shadow"></p>
                </div>
                <div className="technologies-box" data-aos="flip-left"  data-aos-easing="linear" data-aos-duration="800">
                    <img src={images40} className="img-fluid" />
                    <h6>Bootstarp</h6>
                    <p className="box-shadow"></p>
                </div>
            </div>
         </div>
        </div>
        <div className="row why-accetech">
        <div className="col-12">
            <div>
                <h5 className="main-title text-center" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" >
                    <span>Why</span>
                    Acce Global
                </h5>
            </div>
        </div>
        <div className="col-12">
            <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-6" >
                    <div className="acce-global-box" data-aos="zoom-in-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={images20} alt="" />
                        <h6>Innovation</h6>
                        <p>We believe in innovating and meeting customer needs in every project.</p>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6" >
                    <div className="acce-global-box" data-aos="zoom-in-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={images19} alt="" />
                        <h6>Quality-Focused</h6>
                        <p>Our dedication to excellence ensures top-notch results that surpass expectations.</p>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4" >
                    <div className="acce-global-box" data-aos="zoom-in-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={images18} alt="" />
                        <h6>Value for Money</h6>
                        <p>We deliver cost-effective solutions without compromising quality, maximizing customer value.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="row our-process">
         <div className="col-12">
            <div>
                <h5 className="main-title text-center" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" >
                    <span>
                        Our
                        Process
                    </span> How <br /> We Work
                </h5>
            </div>
         </div>
         <div className="col-12 pb-5">
            <div className="row ">
                <div className="col-xxl-3 col-xl-6 col-lg-6 process-work" >
                    <div className="our-process-box" data-aos="flip-up"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={images21} alt="" />
                        <h6>Ideation</h6>
                        <p>
                            We listen to your ideas and identify your business objectives. This phase is crucial as it helps us
                            in setting goals that match your objectives.
                        </p>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 process-work" >
                    <div className="our-process-box" data-aos="flip-up"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={images22} alt="" />
                        <h6>Defining</h6>
                        <p>
                            After understanding your intentions, we create a detailed SOW. Project requirement, cost-analysis and
                            goal-setting are done with your suggestions.
                        </p>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 process-work" >
                    <div className="our-process-box" data-aos="flip-up"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={images23} alt="" />
                        <h6>Designing</h6>
                        <p>
                            A dedicated team will be assigned for your project who will create an action plan, with a clear
                            definition of each component on the wireframe.
                        </p>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 process-work" >
                    <div className="our-process-box" data-aos="flip-up"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={images24} alt="" />
                        <h6>Development</h6>
                        <p>
                            By keeping constant communication, your project will be built as per the finalized design under the
                            defined costs and your supervision.
                        </p>
                    </div>
                </div>
            </div>
         </div>
        </div>
        <div className="row happy-customers">
          <div className="col-9 work-about mb-5" >
            <div className="row">
                <div className="col-8">
                    <div>
                        <h5 className="main-title text-start" data-aos="fade-right">
                            <span>Work About</span> <br /> Trusted by 80+ <br />
                        </h5>
                    </div>
                    <p data-aos="fade-right">
                        Join the esteemed ranks of over 80+ trusted companies who have relied on our expertise and solutions to propel their businesses forward.
                        Our unwavering commitment to excellence and proven track record speak volumes about the quality and reliability of our services.
                        Partner with us and experience the difference trusted by industry leaders worldwide
                    </p>
                    <div className="world-class" data-aos="fade-right">
                        <p><TbChecks className='check-icon' /></p>
                        <h6>100% Client Satisfaction</h6>
                    </div>
                    <div className="world-class" data-aos="fade-right">
                        <p><TbChecks className='check-icon' /></p>
                        <h6>World Class Worker</h6>
                    </div>
                </div>
                <div className="col about-img" >
                    <img src={images25} alt="" className="img-fluid" data-aos="fade-left" />
                    <h6 data-aos="fade-left">550+ <br /> Completed <br /> Projects</h6>
                </div>
            </div>
          </div>
        </div>
        <div className="row">
           <div className="col-12">
            <div>
                <h5 className="main-title text-center" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" >
                    <span>Let's</span> innovate for <br /> your needs with
                </h5>
            </div>
           </div>
           <div className="col-xxl-6 col-xl-6 col-lg-6 d-flex justify-content-center align-items-center" data-aos="zoom-in-up">
            <div className="anything" >
            <form id="addCustomerQueryForm" name="addCustomerQueryForm" onSubmit={handleSubmit}>
            <h6 className="ask-us-anything mt-0">Ask us anything here</h6>
            <div className="row">
          <p className="box-shadow"></p>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <input
              type="text"
              id="name"
              name="Name"
              placeholder="Name"
              className="input-text"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <input
              type="email"
              id="email"
              name="Email"
              placeholder="Email"
              className="input-text"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
               <input
                 type="tel"
                 placeholder="Phone"
                 id="phoneNumber"
                 name="PhoneNumber"
                 className="input-text"
                 value={formData.phoneNumber}
                 onChange={handleChange}
               />
          </div>
          <div className="col-12">
            <input
              type="text"
              id="subject"
              name="Subject"
              placeholder="Subject"
              className="input-text"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
            <textarea
              name="Message"
              id="message"
              placeholder="Message"
              className="message-text"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <p className="box-shadow"></p>
        </div>
        <div className="col-12">
          <button type="submit" className="send-message" id="AddCustomerQuery">
            Send Message
          </button>
        </div>
            </div>
            {formStatus.message && (
              <div className={`form-status ${formStatus.success ? 'success' : 'error'}`}>
                {formStatus.message}
              </div>
            )}
          </form>
            </div>
           </div>
           <div className="col-xxl-6 col-xl-6 col-lg-6 main-title-box">
            <div className="contact">
                <div className="icon-contact" data-aos="zoom-in-right">
                    <img src={images26} alt="" className="img-fluid" />
                    <div className="contact-title">
                        <p className="m-0">Contact</p>
                        <h6>+91 72111 29521 </h6>
                    </div>
                </div>
                <div className="icon-contact" data-aos="zoom-in-right">
                    <img src={images27} alt="" className="img-fluid" />
                    <div className="contact-title">
                        <p className="m-0">Email</p>
                        <h6>salessupport@acceglobalgroup.com</h6>
                    </div>
                </div>
                <div className="icon-contact" data-aos="zoom-in-right">
                    <img src={images28} alt="" className="img-fluid" />
                    <div className="contact-title">
                        <p className="m-0">Address</p>
                        <h6>
                            UNIT NO. ASO-230, TOWER-SOUTH, ASTRATOWERS, NEW TOWN,
                            KOLKATA, North 24Parganas Kolkata- 700161, West Bengal
                        </h6>
                    </div>
                </div>
                <div className="" id="map" data-aos="zoom-in-right">
                    <div className="w-100">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.2110722944126!2d88.43120912101041!3d22.571207666948204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89fa843f35141%3A0xf200e4d62cb7d089!2sACCE%20GLOBAL%20SOFTWARE!5e0!3m2!1sen!2sin!4v1706506155256!5m2!1sen!2sin"
                        width="100%" height="470"  loading="lazy"></iframe>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>
    </>
  )
}

export default Main