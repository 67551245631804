import React from 'react';
import Logo from '../assets/images/logo.png';
import { NavLink, useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    const handleScrollTop = (path: string) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <footer className="row footer">
            <div className="col-xxl-4 col-xl-3 social-icons align-items-start">
                <a className="footer-brand" href="/">
                    <img src={Logo} alt="Acce Global Logo" />
                </a>
                <div>
                    <i className="ti ti-brand-instagram" aria-label="Instagram"></i>
                    <i className="ti ti-brand-facebook" aria-label="Facebook"></i>
                    <i className="ti ti-brand-twitter" aria-label="Twitter"></i>
                    <i className="ti ti-brand-linkedin" aria-label="LinkedIn"></i>
                </div>
            </div>
            <div className="col-xxl-8 col-xl-8 footer-ui">
                <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 d-flex justify-content-start">
                        <div className="footer-list">
                            <p>IMPORTANT LINK</p>
                            <ul>
                                <li>
                                    <button
                                        className="footer-link"
                                        onClick={() => handleScrollTop('/')}
                                    >
                                        Home
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="footer-link"
                                        onClick={() => handleScrollTop('/about')}
                                    >
                                        About us
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="footer-link"
                                        onClick={() => handleScrollTop('/career')}
                                    >
                                        Career
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 d-flex justify-content-start">
                        <div className="footer-list">
                            <p>OUR SERVICES</p>
                            <ul>
                                <li>Web Development</li>
                                <li>App Development</li>
                                <li>Software Development</li>
                                <li>UI/UX Design</li>
                                <li>Digital Marketing</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 d-flex justify-content-start">
                        <div className="footer-list">
                            <p>Head Office</p>
                            <ul>
                                <li>
                                    UNIT NO. ASO-230, TOWER-SOUTH, ASTRATOWERS, NEW TOWN, KOLKATA,
                                    North 24 Parganas Kolkata- 700161, West Bengal
                                </li>
                                <li>
                                    <a href="mailto:salessupport@acceglobalgroup.com">
                                        salessupport@acceglobalgroup.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
